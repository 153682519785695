@tailwind base;
@tailwind components;
@tailwind utilities;

#blink_me {
  animation: blinker 0.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.no-cursor {
  color: transparent;
  text-shadow: 0 0 0 #fff;
}
